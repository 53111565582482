export default {
  data: () => ({
    isDrawing: false,
    lastPoint: null,
    brush: new Image(),
  }),
  methods: {
    getMouse(e, canvas) {
      let offsetX = 0;
      let offsetY = 0;
      if (canvas.offsetParent !== undefined) {
        do {
          offsetX += canvas.offsetLeft;
          offsetY += canvas.offsetTop;
        } while ((canvas = canvas.offsetParent)); // eslint-disable-line
      }
      return {
        x: (e.pageX || e.touches[0].clientX) - offsetX,
        y: (e.pageY || e.touches[0].clientY) - offsetY,
      };
    },

    getFilledInPixels(stride, ctx, cvw, cvh) {
      if (!stride || stride < 1) stride = 1;

      const pixels = ctx.getImageData(0, 0, cvw, cvh);
      const pdata = pixels.data;
      const l = pdata.length;
      const total = l / stride;
      let count = 0;

      // Iterate over all pixels
      // eslint-disable-next-line
      for (let i = (count = 0); i < l; i += stride) {
        if (parseInt(pdata[i], 10) === 0) {
          count++;
        }
      }

      return Math.round((count / total) * 100);
    },

    handleMouseDown(e, canvas) {
      this.isDrawing = true;
      this.lastPoint = this.getMouse(e, canvas);
    },

    handleMouseUp() {
      this.isDrawing = false;
    },

    handleMouseMove(e, cb, canvas, ctx) {
      if (!this.isDrawing) return;
      e.preventDefault();

      const currentPoint = this.getMouse(e, canvas);
      const dist = this.distanceBetween(this.lastPoint, currentPoint);
      const angle = this.angleBetween(this.lastPoint, currentPoint);
      let x;
      let y;

      console.log('###draw', dist, Math.sin(angle), Math.cos(angle));
      for (let i = 0; i < dist; i++) {
        x = this.lastPoint.x;
        y = this.lastPoint.y;
        ctx.globalCompositeOperation = 'destination-out';
        ctx.drawImage(this.brush, x, y);
      }

      this.lastPoint = currentPoint;
      cb(this.getFilledInPixels(32, ctx, canvas.width, canvas.height));
    },

    distanceBetween(point1, point2) {
      return Math.sqrt(Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2));
    },

    angleBetween(point1, point2) {
      return Math.atan2(point2.x - point1.x, point2.y - point1.y);
    },
  },
};
